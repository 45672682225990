import { Flex, styled, Text } from '@conteg/ui';
import env from 'env/env';
import { InfoText } from 'pages/events/info-text';
import { useTranslation } from 'react-i18next';
import {
  AllocationInspectedFragment,
  AllocationTerminatedFragment,
  ContentDeallocatedFragment,
  ContentPickedUpFragment,
  ContentStockedInFragment,
  ContentStockedOutFragment,
  ContentStoredFragment,
  PaymentAbortInfoFragment,
  PaymentProcessedFragment,
  PaymentRejectedFragment,
  PaymentReversalProcessedFragment,
  PaymentReversalRejectedFragment,
  SubmissionCodeValidated,
  UnknownContentFoundFragment,
  VerifyPickUpAccessCredentialsSuccessFragment,
} from 'types/generated/graphql';
import { booleanToYesNo } from 'utils/boolean-to-yes-no/boolean-to-yes-no';

export type TableItem =
  | ContentStockedInFragment
  | ContentStockedOutFragment
  | ContentPickedUpFragment
  | ContentDeallocatedFragment
  | VerifyPickUpAccessCredentialsSuccessFragment
  | ContentStoredFragment
  | AllocationInspectedFragment
  | AllocationTerminatedFragment
  | SubmissionCodeValidated
  | UnknownContentFoundFragment
  | PaymentAbortInfoFragment
  | PaymentProcessedFragment
  | PaymentRejectedFragment
  | PaymentReversalProcessedFragment
  | PaymentReversalRejectedFragment;

type Props = {
  event: TableItem;
};

export const AdditionalInfo = ({ event }: Props) => {
  const { t } = useTranslation();

  if (event.__typename === 'ContentStored') {
    return (
      <Container>
        <InfoText label={t('PhoneNumber')} value={event.phoneNumber} />
      </Container>
    );
  }

  if (event.__typename === 'VerifyPickUpAccessCredentialsSuccess') {
    return (
      <Container>
        <InfoText label={t('PhoneNumber')} value={event.phoneNumber} />
        <InfoText label={t('Pin')} value={event.pinCode} />
        <InfoText label={t('Table.FirstName')} value={event.firstName} />
        <InfoText label={t('Table.LastName')} value={event.lastname} />
        <InfoText label={t('Table.Email')} value={event.email} />
      </Container>
    );
  }

  if (event.__typename === 'ContentStockedIn') {
    return (
      <Container>
        <InfoText
          label={t('Table.CourierIdentifier')}
          value={event.courierIdentifier}
        />
      </Container>
    );
  }

  if (
    event.__typename === 'AllocationInspected' ||
    event.__typename === 'AllocationTerminated' ||
    event.__typename === 'ContentStockedOut' ||
    event.__typename === 'ContentPickedUp'
  ) {
    return (
      <Container>
        <InfoText label={t('Table.FirstName')} value={event.firstName} />
        <InfoText label={t('Table.LastName')} value={event.lastname} />
        <InfoText label={t('Table.Email')} value={event.email} />
        {event.__typename === 'ContentPickedUp' && (
          <InfoText
            label={t('Table.EventSource')}
            value={event.email ? 'Maintenance web' : 'Workflow web'}
          />
        )}
        {event.__typename === 'ContentStockedOut' && (
          <InfoText
            label={t('Table.CourierIdentifier')}
            value={event.courierIdentifier}
          />
        )}
      </Container>
    );
  }

  if (event.__typename === 'SubmissionCodeValidated') {
    return (
      <Container>
        <InfoText
          label={t('Table.IsScanned')}
          value={t(booleanToYesNo(event.isScanned))}
        />
        <InfoText
          label={t('Table.IsSuccessful')}
          value={t(booleanToYesNo(event.isSuccessful))}
        />
      </Container>
    );
  }

  if (event.__typename === 'PaymentAbortInfo') {
    return (
      <Container>
        <InfoText label={t('Table.PointId')} value={event.pointId} />
        <InfoText label={t('Table.SystemNumber')} value={event.systemNumber} />
      </Container>
    );
  }

  if (event.__typename === 'PaymentProcessed') {
    return (
      <Container>
        <InfoText label={t('Table.PointId')} value={event.pointId} />
        <InfoText
          label={t('Table.CardEntryMode')}
          value={t(`CardEntryMode.${event.cardEntryMode}`)}
        />
        <InfoText
          label={t('Table.TransactionResponseCodeName')}
          value={t(
            `TransactionResponseCode.${event.transactionResponseCodeName}`
          )}
        />
        {event.request?.reservationId && event.request?.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <a
              href={`${env.VITE_PAYMENT_WEB_URL}?reservationId=${event.request.reservationId}&subjectId=${event.request.subjectId}`}
            >
              {t('Table.ReceiptLink')}
            </a>
          </Flex>
        )}
      </Container>
    );
  }

  if (event.__typename === 'PaymentRejected') {
    return (
      <Container>
        <InfoText label={t('Table.PointId')} value={event.pointId} />
        <InfoText
          label={t('Table.HostResponseCode')}
          value={event.hostResponseCode}
        />
        <InfoText label={t('Table.PaymentId')} value={event.paymentId} />
        <InfoText
          label={t('Table.ReservationId')}
          value={event.reservationId}
        />
        <InfoText label={t('Table.SubjectId')} value={event.subjectId} />
        <InfoText label={t('Table.SystemNumber')} value={event.systemNumber} />
        <InfoText
          label={t('Table.TransactionId')}
          value={event.transactionId}
        />
        {event.reservationId && event.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <a
              href={`${env.VITE_PAYMENT_WEB_URL}?reservationId=${event.reservationId}&subjectId=${event.subjectId}`}
            >
              {t('Table.ReceiptLink')}
            </a>
          </Flex>
        )}
      </Container>
    );
  }

  if (event.__typename === 'PaymentReversalProcessed') {
    return (
      <Container>
        <InfoText label={t('Table.PointId')} value={event.pointId} />
        <InfoText
          label={t('Table.TransactionResponseCodeName')}
          value={t(
            `TransactionResponseCode.${event.transactionResponseCodeName}`
          )}
        />
        {event.request?.reservationId && event.request?.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <a
              href={`${env.VITE_PAYMENT_WEB_URL}?reservationId=${event.request.reservationId}&subjectId=${event.request.subjectId}`}
            >
              {t('Table.ReceiptLink')}
            </a>
          </Flex>
        )}
      </Container>
    );
  }

  if (event.__typename === 'PaymentReversalRejected') {
    return (
      <Container>
        <InfoText
          label={t('Table.HostResponseCode')}
          value={event.hostResponseCode}
        />
        <InfoText label={t('Table.PaymentId')} value={event.paymentId} />
        <InfoText label={t('Table.PointId')} value={event.pointId} />
        <InfoText
          label={t('Table.ReservationId')}
          value={event.reservationId}
        />
        <InfoText label={t('Table.SubjectId')} value={event.subjectId} />
        <InfoText label={t('Table.SystemNumber')} value={event.systemNumber} />
        <InfoText
          label={t('Table.TransactionResponseCodeName')}
          value={t(
            `TransactionResponseCode.${event.transactionResponseCodeName}`
          )}
        />
        {event.reservationId && event.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <a
              href={`${env.VITE_PAYMENT_WEB_URL}?reservationId=${event.reservationId}&subjectId=${event.subjectId}`}
            >
              {t('Table.ReceiptLink')}
            </a>
          </Flex>
        )}
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
`;
