import { Box, Select } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'utils/routing/route-paths';

export enum FiltrType {
  StorageUnitsTracking = 'STORAGE_UNITS_TRACKING',
  ContentTracking = 'CONTENT_TRACKING',
}

type FilterTypeSelectProps = {
  active: FiltrType;
};

const routerResolver: Record<FiltrType, RoutePaths> = {
  [FiltrType.StorageUnitsTracking]: RoutePaths.StorageUnitsTracking,
  [FiltrType.ContentTracking]: RoutePaths.ContentTracking,
};

export const FilterTypeSelect = ({ active }: FilterTypeSelectProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleFilterTypeChange = (value: FiltrType) =>
    navigate(routerResolver[value]);

  return (
    <Box width="60rem" marginBottom="5rem">
      <Select
        defaultSingleValue={active}
        userSingleValue={active}
        onSingleValueChange={(value) =>
          handleFilterTypeChange(value as FiltrType)
        }
        options={Object.values(FiltrType).map((value) => ({
          value,
          label: t(`Filter.Type.${value}`),
        }))}
        label={t('Filter.FilterType')}
        isNullable={false}
      />
    </Box>
  );
};
