import { Box, Tooltip } from '@conteg/ui';
import LockSvg from 'assets/lock.svg?react';
import UnlockSvg from 'assets/unlock.svg?react';
import { useTranslation } from 'react-i18next';
import { LockStatus } from 'types/generated/graphql';

type StorageUnitTrackingLockStateProps = {
  lockState: LockStatus;
};
export const StorageUnitTrackingLockState = ({
  lockState,
}: StorageUnitTrackingLockStateProps) => {
  const { t } = useTranslation();

  switch (lockState) {
    case LockStatus.Closed:
      return (
        <Tooltip
          content={t('StorageUnitTracking.LockState.Closed')}
          testId="closed-lock-state-tooltip"
        >
          <LockSvg width="25" height="25" />
        </Tooltip>
      );
    case LockStatus.Opened:
      return (
        <Tooltip
          content={t('StorageUnitTracking.LockState.Opened')}
          testId="opened-lock-state-tooltip"
        >
          <Box ml={1}>
            <UnlockSvg width="25" height="25" />
          </Box>
        </Tooltip>
      );
    case LockStatus.Failed:
      return (
        <Tooltip
          content={t('StorageUnitTracking.LockState.Failed')}
          testId="failed-lock-state-tooltip"
        >
          <code>&#10071;</code>
        </Tooltip>
      );
    default:
      return null;
  }
};
