import * as React from "react";
const SvgLock = (props) => /* @__PURE__ */ React.createElement("svg", { height: "800px", width: "800px", id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 512 512", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFB655"
}, d: "M411.151,201.697h-23.273h-46.545h-85.332h-85.333h-46.545h-23.274 c-12.853,0-23.273,10.42-23.273,23.273v263.758c0,12.853,10.42,23.273,23.273,23.273h155.153h155.15 c12.853,0,23.273-10.42,23.273-23.273V224.97C434.424,212.117,424.004,201.697,411.151,201.697z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#C3C3C7"
}, d: "M170.668,131.877c0-47.051,38.281-85.332,85.332-85.332c47.053,0,85.333,38.281,85.333,85.332v69.82 h46.545v-69.82C387.879,59.161,328.718,0,256,0c-72.716,0-131.877,59.161-131.877,131.877v69.82h46.545V131.877z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#EE8700"
}, d: "M100.848,512h155.153V201.697h-85.333h-46.545h-23.274c-12.853,0-23.273,10.42-23.273,23.273v263.758 C77.576,501.58,87.996,512,100.848,512z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#A9A8AE"
}, d: "M124.123,131.879v69.818h46.545v-69.818c0-47.053,38.281-85.333,85.332-85.333V0 C183.284,0,124.123,59.161,124.123,131.879z" }));
export default SvgLock;
